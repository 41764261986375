@font-face {
    font-family: 'RockSalt';
    src: local('RockSalt'), url(./fonts/RockSalt-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'Abel';
    src: local('Abel'), url(./fonts/Abel-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

.rocksalt {
    font-family: 'RockSalt' !important;
}

.abel {
    font-family: 'Abel' !important;
}

.pfp {
    border-radius: 50%;
    padding: 10px;
}

.social {
    padding: 10px !important;
    width: 80px;
    display: inline;
}

.disabled {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.chakra-ui-dark{
    background-color: #000 !important;
}

.player{
    width: 100%;
}

/* .chip1:hover{
    background-image:
} */

.chip1{
    opacity: 0.4;
}

.galleryPhoto:hover{
    opacity: 0;
}
.jake1bg{
    background-image: url(../assets/jake1alt.jpeg);
    background-size: cover;
    background-position: center center;
    width: 400px;
    height: 400px;
}
.jake2bg{
    background-image: url(../assets/jake2alt.jpeg);
    background-size: cover;
    background-position: center center;
    width: 400px;
    height: 400px;
}
.jake3bg{
    background-image: url(../assets/jake3alt.jpeg);
    background-size: cover;
    background-position: center center;
    width: 400px;
    height: 400px;
}
.chip1bg{
    background-image: url(../assets/chip1alt.jpeg);
    background-size: cover;
    background-position: center center;
    width: 400px;
    height: 400px;
}
.chip2bg{
    background-image: url(../assets/chip2alt.jpeg);
    background-size: cover;
    background-position: center center;
    width: 400px;
    height: 400px;
}
.chip3bg{
    background-image: url(../assets/chip3alt.jpeg);
    background-size: cover;
    background-position: center center;
    width: 400px;
    height: 400px;
}
.joey1bg{
    background-image: url(../assets/joey1alt.jpeg);
    background-size: cover;
    background-position: center center;
    width: 400px;
    height: 400px;
}
.joey2bg{
    background-image: url(../assets/joey2alt.jpeg);
    background-size: cover;
    background-position: center center;
    width: 400px;
    height: 400px;
}
.joey3bg{
    background-image: url(../assets/joey3alt.jpeg);
    background-size: cover;
    background-position: center center;
    width: 400px;
    height: 400px;
}