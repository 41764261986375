@font-face {
  font-family: 'Fredoka One';
  src: local('Fredoka One'), url(./fonts/FredokaOne-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), url(./fonts/Nunito-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.App {
  text-align: center;
}
